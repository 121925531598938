<template>
  <v-expansion-panel-header>
    <template v-slot:default="{ open }">
      <v-row
        v-if="open"
        class="d-flex flex-row justify-space-between align-center py-2 px-5"
      >
        <div class="d-flex flex-row align-center">
          <div class="d-flex align-center" v-if="isGroup">
            <avatar-group :items="avatarGroups" class="mr-2" />
            <div>
              <div>{{ $t("group_interview") }}</div>
            </div>
          </div>
          <div
            id="whirelab-div-candidate-panel-open"
            v-else
            class="d-flex flex-row align-center"
          >
            <div class="mr-4">
              <v-avatar>
                <img
                  :src="
                    candidate.interviewee && candidate.interviewee.photo
                      ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                      : `/avatar.png`
                  "
                />
              </v-avatar>
            </div>
            <div class="d-flex flex-column whirelab-div-fullname">
              <span
                v-if="!candidate.interviewee && isInterviewer"
                class="whirela-badge-edit"
                @click="editEmail(candidate.email)"
                >{{ $t("edit") }}</span
              >
              <div>
                {{
                  candidate.interviewee
                    ? candidate.interviewee.fullName
                    : candidate.email
                }}
              </div>
              <div class="caption text--secondary">
                {{
                  candidate.interviewee ? candidate.interviewee.profession : ""
                }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="text--secondary">
            {{ $t("control_entire_interview_process") }}
          </div>
        </div>
        <div class="px-10 mx-12"></div>
      </v-row>
      <v-row
        v-else
        class="d-flex flex-row justify-space-between align-center py-2 px-5"
      >
        <div class="d-flex flex-row align-center">
          <div class="d-flex align-center" v-if="isGroup">
            <avatar-group :items="avatarGroups" class="mr-2" />
            <div>
              <div>{{ $t("group_interview") }}</div>
            </div>
          </div>
          <div
            id="whirelab-div-candidate-panel-closed"
            v-else
            class="d-flex flex-row align-center whirelab-div-candidate"
          >
            <div class="mr-4">
              <v-avatar>
                <img
                  :src="
                    candidate.interviewee && candidate.interviewee.photo
                      ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                      : `/avatar.png`
                  "
                />
              </v-avatar>
            </div>
            <div
              id="whirelab-div-fullname"
              class="d-flex flex-column whirelab-div-fullname"
            >
              <span
                v-if="!candidate.interviewee && isInterviewer"
                class="whirela-badge-edit"
                @click="editEmail(candidate.email)"
                >{{ $t("edit") }}</span
              >
              <div>
                {{
                  candidate.interviewee
                    ? candidate.interviewee.fullName
                    : candidate.email
                }}
              </div>
              <div class="caption text--secondary">
                {{
                  candidate.interviewee ? candidate.interviewee.profession : ""
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row align-center">
          <div>
            <div class="mr-2">{{ $t("date_and_time") }}:</div>
          </div>
          <div>
            <span class="text--secondary">{{ localDate }}</span>
          </div>
        </div>
        <div class="d-flex flex-row align-center justify-space-between">
          <div class="d-flex flex-row align-center mx-10">
            <div class="mr-4">
              <span class="label"
                >{{ currentStageIndex }}º {{ $t("interview") }}</span
              >
            </div>
          </div>
          <div>
            <v-alert
              dense
              outlined
              :color="`${$t(status.color)}`"
              class="py-0 mb-0 caption text-uppercase text-center"
              width="100px"
              >{{ $t(status.text) }}</v-alert
            >
          </div>
        </div>
      </v-row>
    </template>
  </v-expansion-panel-header>
</template>

<script>
import { API_URL } from "@/api";
import format from "date-fns/format";
import { pt, enUS } from "date-fns/locale";
import AvatarGroup from "../candidate/AvatarGroup.vue";
export default {
  name: "ExpansionPanelHeader",

  components: {
    AvatarGroup,
  },

  props: {
    interviewId: { type: String },
    interview: { type: Array },
    isInterviewer: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
  }),

  computed: {
    isGroup() {
      return this.interview.length > 1;
    },
    candidate() {
      const FIRST_ELEMENT = 0;
      return this.interview[FIRST_ELEMENT];
    },
    candidates() {
      return this.interview;
    },
    avatarGroups() {
      return this.interview.map((mapCandidate) => ({
        id: mapCandidate.interviewee
          ? mapCandidate.interviewee.id
          : mapCandidate.email,
        name: mapCandidate.interviewee
          ? mapCandidate.interviewee.fullName
          : mapCandidate.email,
        photo: mapCandidate.interviewee ? mapCandidate.interviewee.photo : null,
      }));
    },
    localDate() {
      const currentLang = this.$i18n.locale;

      const stage = this.candidate.stages.find(
        (findCandidate) => findCandidate.name === this.candidate.currentStage
      );
      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(
            new Date(`${stage.date} ${stage.time}`),
            "yyyy/MM/dd hh:mm a",
            { locale: enUS }
          );
          break;

        default:
          formattedDate = format(
            new Date(`${stage.date} ${stage.time}`),
            "dd/MM/yyyy HH:mm",
            { locale: pt }
          );
          break;
      }

      return formattedDate;
    },
    currentStageIndex() {
      const stageIndex = this.candidate.stages.findIndex(
        (findCandidate) => findCandidate.name === this.candidate.currentStage
      );

      return stageIndex + 1;
    },
    status() {
      let states = [];
      const PENDING = "pending";
      const CANDIDATES = this.interview;
      CANDIDATES.forEach((mapCandidate) =>
        mapCandidate.stages.forEach((mapStage) => {
          if (mapCandidate.status === PENDING) {
            states.push(mapStage.state);
          } else {
            states.push(mapCandidate.status);
          }
        })
      );

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        running = 0,
        canceled = 0,
        ongoing = 0,
        finished = 0,
        rejected = 0,
        approved = 0;
      const PENDING_STATUS = { text: this.$t("pending"), color: "cyan" };
      const ONGOING_STATUS = { text: this.$t("ongoing"), color: "warning" };
      const CANCELED_STATUS = { text: this.$t("canceled"), color: "error" };
      const REJECTED_STATUS = { text: this.$t("rejected"), color: "error" };
      const FINISHED_STATUS = { text: this.$t("finished"), color: "success" };
      const APPROVED_STATUS = { text: this.$t("approved"), color: "success" };

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          case "finished":
            finished += 1;
            break;

          case "approved":
            approved += 1;
            break;

          case "rejected":
            rejected += 1;
            break;

          case "canceled":
            canceled += 1;
            break;

          case "ongoing":
            ongoing += 1;
            break;

          default:
            running += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return PENDING_STATUS;
      } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
        return ONGOING_STATUS;
      } else if (TOTAL_STATUS === canceled) {
        return CANCELED_STATUS;
      } else if (TOTAL_STATUS === approved) {
        return APPROVED_STATUS;
      } else if (TOTAL_STATUS === rejected) {
        return REJECTED_STATUS;
      } else if (
        TOTAL_STATUS === finished ||
        finished + canceled === TOTAL_STATUS
      ) {
        return FINISHED_STATUS;
      }

      return PENDING_STATUS;
    },
  },

  methods: {
    editEmail(candidateEmail) {
      const params = {
        interviewId: this.interviewId,
        candidateEmail,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("EditEmailDialog", params);
    },
  },
};
</script>

<style scoped>
.avatars {
  padding-right: 20px;
}
.avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
}
.avatar:not(:first-child) {
  margin-left: -30px;
  -webkit-mask: radial-gradient(
    circ30 25px at 5px 50%,
    transparent 99%,
    #fff 100%
  );
  mask: radial-gradient(circle 25px at 5px 50%, transparent 99%, #fff 100%);
}
.avatar img {
  width: 100%;
  display: block;
}

.whirelab-div-fullname {
  position: relative;
}

.whirela-badge-edit {
  position: absolute;
  right: 00px;
  top: -20px;
  background: #f7a400;
  text-align: center;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}

.whirela-badge-edit {
  display: none;
}

#whirelab-div-candidate-panel-open:hover .whirela-badge-edit {
  display: block;
}

#whirelab-div-candidate-panel-closed:hover .whirela-badge-edit {
  display: block;
}
</style>